import { Signin } from 'views/signin';
import { Dashboard } from 'views/dashboard';
import { Users } from 'views/users';
import { Organizations } from 'views/organization/list';
import { OrganizationDetail } from 'views/organization/detail';
import { Experiences } from 'views/experience/list';
import { ManageExperience } from 'views/experience/detail';
import {PendingUpdateExperience} from 'views/experience/updates-pending/list';
import {SignupVerification} from 'views/signup/verify';
import {SignupUser} from 'views/signup/user';
import {Bookings} from 'views/booking/list';
import CorsFallback from 'views/cors-fallback';
import {Terms} from 'views/terms-and-conditions/terms';

const Routes = [
  {
    path: '/',
    view: Dashboard,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Mission Control'
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'auth',
    title: 'Mission Control'
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Mission Control'
  },
  {
    path: '/users',
    view: Users,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Users'
  },
  {
    path: '/experiences',
    view: Experiences,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Experiences'
  },
  {
    path: '/experiences-pending',
    view: PendingUpdateExperience,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Pending Update Experiences'
  },
  {
    path: '/bookings',
    view: Bookings,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Booked Experiences'
  },
  {
    path: '/experience/:id',
    view: ManageExperience,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Experience Detail'
  },
  {
    path: '/create-experience',
    view: ManageExperience,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Experience Create'
  },
  {
    path: '/organizations',
    view: Organizations,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Organizations'
  },
  {
    path: '/organizations/create',
    view: OrganizationDetail,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Organization'
  },
  {
    path: '/organizations/:id',
    view: OrganizationDetail,
    layout: 'app',
    permission: 'superAdministrator',
    title: 'Organization'
  },
  { 
    path: '/signup/verify',
    view: SignupVerification,
    layout: 'auth',
    title: 'Please verify your email address'
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'auth',
    title: 'Sign up to VIPSRV'
  },
  {
    path: '/cors-fallback',
    view: CorsFallback,
    layout: 'auth'
  }
  ,
  {
    path: '/terms',
    view: Terms,
    layout: 'auth',
    title: 'Terms & Conditions'
  },

]

export default Routes;