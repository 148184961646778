const Style = {

  base: `relative text-center mb-4  md:mb-6`,

  title: 'font-semibold mb-2',
  subtitle: 'font-semibold mt-0 mb-10 text-xl md:text-2xl md:mb-16',
  brand: 'text-white',
  dark: 'text-white',
  light: 'text-brand-500',
  desc: 'text-center'

}

export default Style;