/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, {useEffect} from 'react';
import {Animate, AuthContext, Message, Row} from 'components/lib';
import {useContext} from 'react';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {sanitizeRedirectUrl} from 'util/sanitize-redirect-url';


const customStyles = `
  .auth0-lock.auth0-lock .auth0-lock-header {
    display: none !important;
  }
 
  .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 0 !important;
  }
    
  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
  }
  .auth0-loading {
    position: static !important;
  }
  .auth0-lock-content-wrapper {
    overflow: hidden !important;

  }
  .auth0-lock .auth0-loading-screen {
    position: static !important;
  }
  .auth0-loading-screen .auth0-loading-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-submit  .auth0-loading-container {
    position: absolute !important;
    top: 0 !important;
    right: calc(50% - 17px) !important;
  }


  .auth0-lock-content-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-grow: 0 !important;
  }
  .auth0-lock-mode-loading button .auth0-loading-container {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-submit {
    border-radius: 52px;
    margin-top:32px;
    position: relative !important;

  }
    .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
        padding-left: 0 !important;
                border-radius: 8px !important;

        background-color: transparent !important;
    }
            .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon svg {
            display: none;
            }
             .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon input {
                border-radius: 8px !important;
             }
            .auth0-lock-widget {
                margin: 0 !important;
                width: 100% !important;
            }
                .auth0-lock-form {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                }
                    .auth0-label-submit {
    font-family: "Plus Jakarta Sans" !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 16px !important; 
height: 16px !important; 
text-transform: none !important;
}
 .auth0-label-submit svg {
    display:none !important;
 }
    .auth0-lock-alternative {
        margin-top: 16px !important;
        text-align: left !important;
       
    }
        .auth0-lock-alternative-link {
 color: #1F775E !important;
        font-family: "Plus Jakarta Sans" !important;
        }

        .auth0-lock-widget-container {
        }
      
            .auth0-lock-content-body-wrapper {
              width: 100%; 
            }
`;

export function Signin(props) {
  const authContext = useContext(AuthContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = customStyles;
    document.head.appendChild(styleElement);
  }, []);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      return; 
    }

    // handle case when user is redirected back from MFA
    const hash = location.hash;
    const accessToken = hash.match(/access_token=([^&]*)/)?.[1];
    const authError = hash.match(/error=([^&]*)/)?.[1];
    if (accessToken || authError) {
      authContext.checkSessionCb(authError, accessToken).then();
      if (authError && location.pathname === '/signin') {
        authContext.lock.show();
      }

      return;
    }
    if (localStorage.getItem('isAuthenticated') === 'true' && location.pathname === '/signin') {
      authContext.lock.checkSession({
        redirectUri: window.location.origin + '/signin',
      }, async (err, authResult) => {
        await authContext.checkSessionCb(err,  authResult ? authResult.accessToken : null);
        if (err && location.pathname === '/signin') {
          authContext.lock.show();
        }
      })
    } else {
      authContext.lock.show();
    }
  }, [authContext.lock, authContext.checkSessionCb, location.pathname, location.hash]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      const savedPath = sanitizeRedirectUrl(localStorage.getItem('savedPath'));
      const savedQuery = localStorage.getItem('savedQuery');

      // Clear saved path/query from localStorage
      localStorage.removeItem('savedPath');
      localStorage.removeItem('savedQuery');

      if (savedPath) {
        navigate(savedPath + (savedQuery || ''));
      } else {
        if (!authContext.user?.verified) {
          navigate('/signup/verify');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, [authContext.isAuthenticated, authContext.user, navigate]);

  return (
    <Animate type='pop'>
      <Row title='Sign in to Mission Control' color='black'>
        {searchParams.get("reason") === 'account_disabled' ?
          <Message
            type={'error'}
            title={"Account Disabled"}
            text={"Your account has been disabled"}
            button={false}
          /> : <></>
        }
        <div id="auth0-lock-container" className="mx-auto mb-5 max-w-[414px] w-full" />

      </Row>
    </Animate>
  );
}
